<template>
  <div>
    <audio ref="audio" :src="src">
    </audio>

  </div>
</template>
<style>

</style>

<script>
import audioFile from "@/assets/audio/dronework_drone_fly_alarm.mp3";
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
//https://github.com/ankurk91/vue-toast-notification/tree/v1.x
//npm install vue-toast-notification@^0.6
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export default {
  props: {
  },
  components: {
  },
  data() {
    return {
      src : audioFile,
      isPlay : false,
      audio : null,
      toast : null,
      isUserCheck : false,
      toastOption: {
        message: "드론 비행 알람 소리를 위해서 화면을 클릭 해주세요.",
        type: 'error',
        position : 'top',
        duration : 0,
        // all of other options may go here
      }
    };
  },
  computed : {
  },
  created() {
    let vue = this;
    document.addEventListener("keydown", function () {
      vue.isUserCheck = true;
      vue.showToast()
      vue.toast.dismiss();
    })
    document.addEventListener("mousedown", function () {
      vue.isUserCheck = true;
      vue.showToast()
      vue.toast.dismiss();
    })
    // this.audio = new Audio(this.src);
  },
  mounted() {
    this.onFirstPlayCheck();
  },
  beforeDestroy() {
  },
  watch: {
  },
  methods: {
    getBrowserInfo() {
      var agent = navigator.userAgent.toUpperCase();
      if (agent.indexOf('TRIDENT') >= 0) {
        return 'IE';
      } else if (agent.indexOf('FIREFOX') >= 0) {
        return 'FIREFOX';
        // Chrome과 Safari, Edge는 같이 웹킷을 사용한다. 역순으로 배치.
      } else if (agent.indexOf('EDG') >= 0) {
        return 'EDGE';
      } else if (agent.indexOf('SAFARI') >= 0) {
        return 'SAFARI';
      } else if (agent.indexOf('CHROME') >= 0) {
        return 'CHROME';
      } else {
        return '';
      }
    },
    showToast(){
      if(this.toast == null) {
        this.toast = Vue.$toast.open(this.toastOption);
      }
    },
    onFirstPlayCheck(){
      this.$refs.audio.muted = true;
      var playedPromise = this.$refs.audio.play();
      if (playedPromise) {
        this.$refs.audio.pause();
        this.$refs.audio.muted = false;
        playedPromise.catch((e) => {
          if(this.isUserCheck) {
            return;
          }
          if(e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
            this.showToast();
          }
        }).then(() => {
        });
      }
    },
    onPlayCheck(){
      var playedPromise = this.$refs.audio.play();
      if (playedPromise) {
        playedPromise.catch((e) => {
          if(this.isUserCheck) {
            return;
          }
          if(e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
            this.showToast();
          }
        }).then(() => {
          if(this.isUserCheck) {
            this.onAudioPlay();
          }
        });
      }
    },
    onAudioPlay(){
      if(!this.$refs.audio.paused) {
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
      }
      this.$refs.audio.play();
    },
    onPlay() {
      this.onPlayCheck();
    }
  },
};
</script>
